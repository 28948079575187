import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBubu11GoVA2huSZo1FFxsaSMvYsMSxJKc",
  authDomain: "sumo888-4ab49.firebaseapp.com",
  projectId: "sumo888-4ab49",
  storageBucket: "sumo888-4ab49.appspot.com",
  messagingSenderId: "808446129480",
  appId: "1:808446129480:web:e3fc715103ee4b806b2f7d",
  measurementId: "G-J08D34WLJB",
};

const app = initializeApp(firebaseConfig, "secondary");
let messaging = null;
try {
  messaging = getMessaging(app);
} catch (error) {
  console.log("Does not support current browser or be use https");
}
const database = getDatabase(app);
const vapidKey =
  "BHzjcdGdUHweUm-ZMu0zZcMtxeDNxNTkIgSXPJwCCOjW4Y4uY4ghM-gKfHxUiRsAfuzrsqLTyiUetk8FKKaWT2o";

export { ref, onValue, database, messaging, onMessage, vapidKey, getToken };
