// @ts-nocheck
import React, { useState, useContext, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useLocation, useParams } from "react-router-dom";
import { LoginContext } from "../../store/context/LoginProvider";
import { Gameplay, GetGameProviders } from "../../api/gameManagement";
import { GAME_PROVIDER } from "../../store/actions/actionTypes";
import { BaseURL } from "../../api/apiUrl";
import { Fragment } from "react";
import ProviderNav from "../../components/Game/ProviderNav";
import SingleGame from "../../components/Game/SingleGame";
import { RandColor } from "../../components/Game/RandColor";

const Category = () => {
  const [loading, setLoading] = useState(true);
  const { cat } = useParams();
  const location = useLocation();
  const [games, setGames] = useState([]);
  const [color, setColor] = useState("");
  const [iframeLoading, setIframeLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");
  const [
    {
      gameManagement: { category, provider },
      userData,
    },
    dispatch,
  ] = useContext(LoginContext);

  useEffect(() => {
    setLoading(true);
    const data = category.filter(({ name }) =>
      name.toLowerCase().includes(cat?.toLowerCase())
    );

    data &&
      GetGameProviders(data[0]?.id).then((res) => {
        res.success && dispatch({ type: GAME_PROVIDER, payload: res.data });
        const game = category
          .filter((a) => a.id === data[0]?.id)
          .map((b) => b.category_games);
        setGames(game && game[0]);
        setColor(RandColor());
        setLoading(false);
      });
  }, [category, cat, dispatch]);

  const handleClick = (p_code, cate_code, g_code) => {
    // console.log(p_code, cate_code, g_code, userData.user_code);
    if (userData) {
      setIframeLoading(true);
      Gameplay(userData.user_code, p_code, cate_code, g_code)
        .then((data) => {
          let url = data.Url;
          window.open(url, "_blank");
          setIframeLoading(false);
          // console.log(data.Url);
        })
        .catch((err) => console.log(err));
    } else {
      setLoading(false);
      alert("Need to login first !");
    }
  };

  const FilterGames = () => {
    if (search) {
      return games.filter((game) =>
        game.name.toLowerCase().includes(search.toLowerCase())
      );
    }
    return games;
  };

  return (
    <div className="col-12">
      <ProviderNav {...{ loading, provider, cat }} />
      {games && (
        <SingleGame
          {...{
            setSearch,
            loading,
            FilterGames,
            handleClick,
            color,
          }}
        />
      )}
    </div>
  );
};

export default Category;
