// The whole project URL files.
export const BaseURL = "https://backend.sumo888.org";
// export const BaseURL = "http://localhost:8000";

// For Auth Api
export const LoginURL = `${BaseURL}/api/user/login`;
export const CheckPhoneURL = `${BaseURL}/api/user/check_phone`;
export const GetOtpURL = `${BaseURL}/api/user/send_otp`;
export const VerifyOtpURL = `${BaseURL}/api/user/verify_otp`;
export const RegisterURL = `${BaseURL}/api/user/verify_register`;
export const ResetPasswordURL = `${BaseURL}/api/user/forget_password`;
export const SaveDeviceTokenURL = `${BaseURL}/api/user/save_device_token`;

// For Home Api
export const GetBannerURL = `${BaseURL}/api/banner`;
export const GetMobileBannerURL = `${BaseURL}/api/banner_mobile`;
export const GetHotGamesByCatURL = `${BaseURL}/api/game/hot`;
export const GetSocialLinkURL = `${BaseURL}/api/social_link`;
export const GetHeaderTextURL = `${BaseURL}/api/header_play_text`;

// For All Games : football, live-casino, slot, card-games, fishing, other games
export const GameTransferin = `${BaseURL}/api/user/transfer_in`;
export const GameTransferout = `${BaseURL}/api/user/transfer_out`;

// For Payment Api
export const GetCashInPaymentsURL = `${BaseURL}/api/payment-method/show-cashin`;
export const GetCashInAmountURL = `${BaseURL}/api/cashin-amount`;
export const GetCashOutPaymentsURL = `${BaseURL}/api/payment-method/show-cashout`;
export const GetCashOutAmountURL = `${BaseURL}/api/cashout-amount`;
export const ReqCashInPaymentURL = `${BaseURL}/api/cash-in`;
export const ReqCashOutPaymentURL = `${BaseURL}/api/cash-out`;

// For Profile Api
export const GetUserProfileURL = `${BaseURL}/api/user/profile`;
export const UpdateUserProfileURL = `${BaseURL}/api/user/profile_update`;
export const ChangePassURL = `${BaseURL}/api/user/new_password`;
export const UpgradeLevel2URL = `${BaseURL}/api/user/lvl_2`;

// For One D Lottery Api
export const GetOneDDataURL = `${BaseURL}/api/new_one_d`;
export const GetSectionsOneDURL = `${BaseURL}/api/section_1d`;
export const Thai1DBetURL = `${BaseURL}/api/user/betting_1d`;
export const GetLuckyNumbersOneURL = `${BaseURL}/api/luckynumber1d`;

// For Two D Lottery Api
export const GetTwoDLiveDataURL = "https://api.thaistock2d.com/live27";
export const GetLuckyNumbersURL = `${BaseURL}/api/luckynumber_daily`;
export const GetSectionsURL = `${BaseURL}/api/section`;
export const GetTwoDDataURL = `${BaseURL}/api/new_two_d`;
export const Thai2DBetURL = `${BaseURL}/api/user/betting`;

export const GetC1DSectionsURL = `${BaseURL}/api/section_crypton1d`;
export const GetC1DDataURL = `${BaseURL}/api/crypto_one_d`;
export const C1DBetURL = `${BaseURL}/api/user/betting_c1d`;

// For Crypto Two D Lottery Api
export const GetC2DSectionsURL = `${BaseURL}/api/section_crypton2d`;
export const GetC2DDataURL = `${BaseURL}/api/crypto_two_d`;
export const C2DBetURL = `${BaseURL}/api/user/betting_c2d`;

// For Three D Lottery Api
export const GetThreeDDataURL = `${BaseURL}/api/three_d`;
export const GetThreeDSectionsURL = `${BaseURL}/api/section_3d`;
export const Thai3DBetURL = `${BaseURL}/api/user/betting_3d`;

// For History Api
export const GetOneDHistoryURL = `${BaseURL}/api/user/bet_slip_history_1d`;
export const GetTwoDHistoryURL = `${BaseURL}/api/user/bet_slip_history_2d`;
export const GetThreeDHistoryURL = `${BaseURL}/api/user/bet_slip_history_3d`;
export const GetCTwoDHistoryURL = `${BaseURL}/api/user/bet_slip_history_c2d`;
export const GetCOneDHistoryURL = `${BaseURL}/api/user/bet_slip_history_c1d`;
export const GetCashInHistoryURL = `${BaseURL}/api/user/cash_in_history`;
export const GetCashOutHistoryURL = `${BaseURL}/api/user/cash_out_history`;
export const GetWinNumberHistoryURL = `${BaseURL}/api/luckynumber_history`;
export const GetWinnerListHistoryURL = `${BaseURL}/api/winner_users`;

//For GameManagement Api
export const GetGameCategoryURL = `${BaseURL}/api/g/categories`;
export const GetGameProviderURL = `${BaseURL}/api/g/providers`;
export const GetGameURL = `${BaseURL}/api/g`;
export const GameLunch = `${BaseURL}/api/getgamelaunch`;

//For Transition API
export const GetTransitionHistoryURL = `${BaseURL}/api/transition/history`;
export const GetTransitionDetailURL = `${BaseURL}/api/transition/details`;

//Referral API
export const GetReferralHistoryURL = `${BaseURL}/api/user/my_referral_his`;
export const ClaimGameReferralURL = `${BaseURL}/api/user/claim_game_refer_amt`;

export const Lotterylist = `${BaseURL}/api/lottery/list`;

//point
export const PointList = `${BaseURL}/api/point/list`;
export const postPointURL = `${BaseURL}/api/point/claim`;
export const GetPointHistory = `${BaseURL}/api/point/history`;
export const PointWarning = `${BaseURL}/api/point/warning`;

//Promotion
export const PromoMaintain = `${BaseURL}/api/weekly/promo_maintain`;
export const PromoClaim = `${BaseURL}/api/promotion/claim`;
export const PromoHistory = `${BaseURL}/api/promotion/claim/history`;
