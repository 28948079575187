import React from "react";
import { useState } from "react";
import lcimage from "../../asset/image/livechat.png";

const LiveChat = () => {
  const [open, setIsOpen] = useState(false);
  return (
    <main>
      <div onClick={() => setIsOpen(!open)} className="livechat football">
        <img style={{ width: "5.5rem" }} src={lcimage} alt="livechat" />
      </div>
      <div className={`${open ? "d-block" : "d-none"} lciframe`}>
        <iframe
          src="https://m.sumo888.org/livechat.html"
          title="Live Chat Widget"
          className="livechatiframe"
        />
      </div>
    </main>
  );
};

export default LiveChat;
