import React, { Fragment } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
const SingleGame = ({
  setSearch,
  loading,
  FilterGames,
  handleClick,
  color,
}) => {
  const { t } = useTranslation();
  return (
    <div className="col-12 col-md-11 mx-auto mt-3 mt-md-0">
      <div className="d-flex justify-content-center justify-content-md-end align-items-center me-md-4">
        <div className="col-10 col-md-3 border border-warning rounded-5 overflow-hidden mt-2">
          <input
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            className="searchgame text-warning col-10 py-1 ms-3 border-0 fs-6 bg-transparent"
            placeholder="Search ..."
          />
          <MagnifyingGlassIcon
            className="text-warning"
            style={{ width: "1.6rem" }}
          />
        </div>
      </div>
      <div className="w-100 mt-4 d-flex justify-content-center align-items-center flex-wrap gap-4 gap-md-5 mx-auto pb-5 mb-5">
        {!loading ? (
          FilterGames().length ? (
            FilterGames().map((game, idx) => (
              <div
                onClick={() =>
                  handleClick(game.p_code, game.cate_code, game.g_code)
                }
                className={`${color} bg-opacity-25 rounded-3 mb-4 mb-md-2 football `}
                style={{
                  width: "10rem",
                  height: "9.5rem",
                }}
                key={idx}
              >
                <LazyLoadImage
                  width={"100%"}
                  height={"100%"}
                  style={{
                    objectFit: "cover",
                  }}
                  src={game.image}
                  effect="blur"
                  className="border border-2 shadow border-warning rounded-2 border-opacity-75 cursor-pointer"
                />
                <div className="text-info font-monospace fw-bolder text-center mt-2">
                  <span className=""> {game.name.substring(0, 15)}</span>
                  <small>{game.name.length >= 15 && "..."}</small>
                </div>
              </div>
            ))
          ) : (
            <div className="font-monospace text-warning fs-4 mt-5 pt-5 text-center">
              {t("noresult")}
            </div>
          )
        ) : (
          <Fragment>
            {Array.from({ length: 21 }).map((_, idx) => (
              <div key={idx}>
                <div
                  className={`bg-warning pulse bg-opacity-10 rounded-3`}
                  style={{
                    width: "10rem",
                    height: "9.3rem",
                  }}
                ></div>
                <div
                  className={`pulse bg-warning mt-2 rounded-5 bg-opacity-10`}
                  style={{ height: "1.4rem" }}
                ></div>
              </div>
            ))}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default SingleGame;
