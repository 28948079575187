// @ts-nocheck
import { useContext, useEffect, useRef, useState } from "react";
// lib
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import DownloadLink from "./components/home/DownloadLink";
import { GetGameCategories } from "../src/api/gameManagement";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
// layouts
import Header from "./layout/Header";
import Navbar from "./layout/Navbar";
import Footer from "./layout/Footer";
// routes
import MainRoute from "./routes/MainRoutes";
// css
import "./asset/css/home.css";
// utils
import { toastError } from "./utils/toast";
// context
import { LoginContext } from "./store/context/LoginProvider";
// actionTypes
import {
  GAME_BAL_CHANGE,
  SET_USER_DATA,
  USER_LOGOUT,
} from "./store/actions/actionTypes";
// Api
import { getProfileApi } from "./api/profileApi";
import {
  getHeaderTextApi,
  getHotProviers,
  getLotteryApi,
  getSocialLinkApi,
} from "./api/homeApi";
// Firebase
import {
  database,
  messaging,
  onMessage,
  onValue,
  ref,
} from "./firebase/myvip_firebase";
import Privacy from "./pages/Privacy";
import { Sidebar } from "./components/sidebar/Sidebar";
import LiveChat from "./components/home/LiveChat";

function App() {
  const [marquee, setMarquee] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [lottery, setLottery] = useState([]);
  const [socialLink, setSocialLink] = useState({
    facebook: "",
    viber: "",
    messenger: "",
    instagram: "",
    play_store: "",
    qr: "",
  });
  // context
  const [{ userData }, dispatch] = useContext(LoginContext);
  // react router dom
  let navigate = useNavigate();
  let location = useLocation();
  let path_name = location?.pathname;
  let is_game_view = location?.state?.is_game_view;

  const slotRef = useRef(null);
  const fishRef = useRef(null);
  const casinoRef = useRef(null);
  const sportRef = useRef(null);
  const cardRef = useRef(null);

  const [provider, setProvider] = useState([]);
  const [hotLoading, setHotLoading] = useState(false);

  const HotProviders = async () => {
    setHotLoading(true);
    setProvider([
      //sport
      {
        title: "category.football",
        ref: sportRef,
        category: "Sport%20Book",
        provider: await getHotProviers(3).then(
          (res) => res.success && res.data
        ),
      },
      //live casino
      {
        title: "category.liveCasino",
        ref: casinoRef,
        category: "LIVE-CASINO",
        provider: await getHotProviers(2).then(
          (res) => res.success && res.data
        ),
      },
      //slot
      {
        title: "category.slotGame",
        ref: slotRef,
        category: "Slot",
        provider: await getHotProviers(1).then(
          (res) => res.success && res.data
        ),
      },
      //fishing
      {
        title: "category.fishingGame",
        ref: fishRef,
        category: "Fishing",
        provider: await getHotProviers(8).then((res) => {
          return res.success && res.data;
        }),
      },
      //card
      {
        title: "category.cardGame",
        ref: cardRef,
        category: "Card%20Game",
        provider: await getHotProviers(6).then((res) => {
          setHotLoading(false);
          return res.success && res.data;
        }),
      },
    ]);
  };

  useEffect(() => {
    HotProviders();
    getLotteryApi().then((res) => setLottery(res));
  }, [loading]);

  useEffect(() => {
    setLoading(true);
    GetGameCategories().then((res) => {
      res.success && dispatch({ type: "GAME_CATEGORY", payload: res.data });
      setLoading(false);
    });
    // Fetch Social Link
    getSocialLinkApi().then(setSocialLink);
    // Fetch Header Text
    getHeaderTextApi().then(setMarquee);
    // Firebase Noti: Foreground
    try {
      onMessage(messaging, (payload) => {
        const { title, body, icon } = payload.notification;
        const option = {
          body,
          icon,
        };
        new Notification(title, option);
      });
    } catch (error) {
      console.log("Firebase Noti Error:", error);
    }

    // Game Balance From Firebase
    try {
      if (userData?.id) {
        let refer = ref(database, `users/balances/${userData?.id}`);
        onValue(refer, (snapshot) => {
          let { last_balance } = snapshot.val();
          // Save or Update Game Balance
          dispatch({
            type: GAME_BAL_CHANGE,
            payload: {
              game_balance: last_balance || 0,
            },
          });
        });
      }
    } catch (error) {
      console.log("Firebase Game Balance:", error);
    }
    // Firebase Noti: Background
    try {
      //BroadcastChannel to recieve background messaging
      const channel = new BroadcastChannel("myvip_broadcast_channel");
      channel.onmessage = (event) => {
        let { title, body, icon } = event.data.key?.data;
        //notification body
        const options = {
          body,
          icon,
          vibrate: true, //vibrate will work only on the mobile device
        };
        new Notification(title, options);
      };
    } catch (error) {
      console.log("Broadcast Error:");
    }
  }, []);

  useEffect(() => {
    if (userData) {
      // Fetch Profile
      getProfileApi({ token: userData?.token })
        .then((res) => {
          let payload = {
            ...userData,
            lvl_2: res?.data?.lvl_2,
            balance: res?.data?.balance,
            user_code: res?.data?.user_code,
            refer_count: res?.data?.refer_count,
            game_refer_amt: res?.data?.game_refer_amt,
            turnover: res?.data?.turnover,
            point: res?.data?.point,
            p_balance: res?.data?.p_balance,
          };
          // Save or Update Profile Data
          dispatch({
            type: SET_USER_DATA,
            payload,
          });
        })
        .catch((err) => {
          console.log(err);
          if (err) {
            toastError("Session Expired, Please Login Again!");
            dispatch({
              type: USER_LOGOUT,
            });
            navigate("/login");
          }
        });
    }
  }, [path_name]);

  return (
    <>
      {/* Download Links */}
      <DownloadLink socialLink={socialLink} />
      <LiveChat />
      <div className="w-100 mx-auto h-100 d-flex mb-4">
        <ToastContainer />
        <div className="w-100 mx-auto">
          <Header marquee={marquee} loading={loading} />
          <div className="col-12 mx-auto mrgTop">
            <MainRoute
              provider={provider}
              hotLoading={hotLoading}
              lottery={lottery}
            />
          </div>
        </div>
      </div>

      <div style={{ zIndex: "100" }} className="col-12 position-fixed bottom-0">
        <Footer {...{ socialLink }} />
      </div>
    </>
  );
}

export default App;
