import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useLocation } from "react-router-dom";
import { BaseURL } from "../../api/apiUrl";
import { Fragment } from "react";

const ProviderNav = ({ loading = false, provider, cat }) => {
  const location = useLocation();
  const test = location.pathname
    .substring(1, location.pathname.length)
    .split("/");
  return (
    <div className="d-none d-md-flex justify-content-center flex-md-wrap overflow-auto gap-md-2 align-items-center">
      {!loading && provider.length && (
        <Link
          to={`/${cat}`}
          className={`cursor-pointer ${
            !test[1] ? "bg-danger" : "bg-dark"
          } text-decoration-none mb-2 football d-flex align-items-center gap-2 overflow-hidden py-2 px-4 rounded-5 `}
        >
          <div className="fw-bolder font-monospace text-warning">ALL</div>
        </Link>
      )}

      {!loading ? (
        provider?.map((data, idx) => (
          <Link
            to={`/${cat}/${data.id}`}
            key={idx}
            className={`cursor-pointer ${
              data.id === +test[1]
                ? "bg-danger text-white"
                : "bg-dark text-warning"
            } text-decoration-none mb-2 football d-flex align-items-center gap-2 overflow-hidden py-2 px-4 rounded-5 `}
          >
            <LazyLoadImage
              effect="blur"
              style={{
                width: "100%",
                height: "1.3rem",
                objectFit: "contain",
              }}
              className=""
              src={
                data.id === 14 && cat === "Fishing"
                  ? `${BaseURL}/${data.sec_image}`
                  : `${BaseURL}/${data.image}`
              }
            />
            <div className="fw-bolder d-none d-md-block font-monospace">
              {data.name}
            </div>
          </Link>
        ))
      ) : (
        <Fragment>
          {Array.from({ length: 8 }).map((_, idx) => (
            <div
              key={idx}
              className="pulse catproviderDiv bg-dark bg-opacity-75 cursor-pointer rounded-4 pb-4 shadow"
            ></div>
          ))}
        </Fragment>
      )}
    </div>
  );
};

export default ProviderNav;
