// @ts-nocheck
import React, { Fragment, useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation, useParams } from "react-router-dom";
import { Gameplay, GetGameProviders, GetGames } from "../../api/gameManagement";
import { LoginContext } from "../../store/context/LoginProvider";
import { RandColor } from "../../components/Game/RandColor";
import { Empty } from "../../components/Game/Empty";
import Loading from "../../components/shared_comp/Loading";
import Iframe from "../../components/Game/Iframe";
import SingleGame from "../../components/Game/SingleGame";
import ProviderNav from "../../components/Game/ProviderNav";
import { GAME_PROVIDER } from "../../store/actions/actionTypes";

const ProviderGame = () => {
  const [
    {
      gameManagement: { category, provider },
    },
  ] = useContext(LoginContext);
  const [{ userData }, dispatch] = useContext(LoginContext);
  // const [user_code,setUser_code] =useState(userData.user_code)
  const { cat } = useParams();
  const location = useLocation();
  const [games, setGames] = useState([]);
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(true);
  const [iframeLoading, setIframeLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");

  const handleClick = (p_code, cate_code, g_code) => {
    // console.log(p_code, cate_code, g_code);
    if (userData) {
      setIframeLoading(true);
      Gameplay(userData.user_code, p_code, cate_code, g_code)
        .then((data) => {
          let url = data.Url;
          window.open(url, "_blank");
          setIframeLoading(false);
          // console.log(data.Url);
        })
        .catch((err) => console.log(err));
    } else {
      setLoading(false);
      alert("Need to login first !");
    }
  };

  const FilterGames = () => {
    if (search) {
      return games.filter((game) =>
        game.name.toLowerCase().includes(search.toLowerCase())
      );
    }
    return games;
  };

  useEffect(() => {
    setLoading(true);
    const data = category.filter(({ name }) =>
      name.toLowerCase().includes(cat?.toLowerCase())
    );

    data &&
      GetGameProviders(data[0]?.id).then((res) => {
        res.success && dispatch({ type: GAME_PROVIDER, payload: res.data });
      });

    const providerID = location.pathname.split("/").pop();
    const categoryID = category
      ?.filter((cate) => cate.name.toLowerCase().includes(cat.toLowerCase()))
      .map((a) => a.id);

    GetGames(...categoryID, providerID).then((data) => {
      data && setGames(data);
      setLoading(false);
    });
    setColor(RandColor());
  }, [cat, category, dispatch, location.pathname]);

  if (!provider && !games && !loading) {
    return <Empty />;
  }
  return (
    <>
      {iframeLoading ? (
        <Loading loading={true} full={true} />
      ) : (
        <Fragment>
          <ProviderNav {...{ provider, cat }} />

          <SingleGame
            {...{
              setSearch,
              loading,
              FilterGames,
              handleClick,
              color,
            }}
          />
        </Fragment>
      )}
      {showIframe && (
        <Iframe
          setIframeUrl={setIframeUrl}
          setShowIframe={setShowIframe}
          iframeUrl={iframeUrl}
        />
      )}
    </>
  );
};

export default ProviderGame;
